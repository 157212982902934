
// @ts-nocheck
import { Component, Vue } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import Events from '@/components/products/events.vue'
import {
  getUserById,
  getLocations,
  postSeller,
  putSeller,
} from '@/services/seller.service'
import { ErrorMessages } from '@/helpers/constants'
import { UserInterface } from '@/interfaces/user.interface'
import { getMyData } from '@/services/user.service'
import { Validate } from 'vuelidate-property-decorators'
import { minLength, sameAs } from 'vuelidate/lib/validators'
import axios from '@/services/base.service'

@Component({
  components: {
    SellerHeader,
    Events,
  },
})
export default class SellerForm extends Vue {
  seller: UserInterface = {}
  @Validate({ minLength: minLength(8) }) password = ''

  @Validate({ sameAsPassword: sameAs('password') }) passwordRepeat = ''

  userId = ''
  title = 'Crear vendedor'
  editMode = false
  options: [] = []
  business_id: any = ''
  location = ''
  isBoss: boolean = false
  passwordRepeat = ''
  events!: any

  relatedProductsOfLocation = null
  relatedProductsOfLocationEditing = null

  validateUsername() {
    let username = this.seller.username
    username = username.normalize('NFD') 
    username = username.replace(/[\u0300-\u036f]/g, '') 
    username = username.replace(/\s+/g, '')
    username = username.toLowerCase()
    this.seller.username = username
  }

  validateEmail() {
    let email = this.seller.email
    email = email.normalize('NFD') 
    email = email.replace(/[\u0300-\u036f]/g, '')
    this.seller.email = email
  }

  async getSellerData(userId) {
    try {
      const data = await getUserById(userId)

      this.seller = data.data.data
      //TODO
      this.location = this.seller.location.length
        ? this.seller.location[0].id
        : ''
      if (this.location) {
        this.getProductsOfLocationEdited()
        this.isBoss = this.seller.location[0].pivot.is_boss
      }
    } catch (error) {
      Vue.$toast.error(ErrorMessages.COMMON)
    }
  }

  async getLocations(business_id) {
    try {
      const data = await getLocations(business_id)
      this.options = data.data.data.map(location => {
        return { text: location.name, value: location.id }
      })
    } catch (e) {
      console.error(e)
      Vue.$toast.error(ErrorMessages.COMMON)
    }
  }

  products(data) {
    console.log('productos', data)
  }

  async submitEdit() {
    let products = this.events.flatMap(obj => {
      return obj.products
        .filter(product => product.selected)
        .map(product => product.id)
    })

    try {
      const seller = JSON.parse(JSON.stringify(this.seller))
      delete seller.location
      seller.password = this.password

      const body = {
        user: seller,
        location: this.location,
        is_boss: this.isBoss,
        products: products
      }

      const response: any = await putSeller(seller.id, body)

      if (response.status == 200) {
        this.getSellerData(seller.id)
        Vue.$toast.success(`Usuario actualizado correctamente`)

        setTimeout(() => {
          this.$router.push('/seller/administration/seller-list')
        }, 1000)
      } else {
        Vue.$toast.error('Ha ocurrido un error actualizando el usuario')
      }
    } catch (e) {
      console.error(e)
      Vue.$toast.error(ErrorMessages.COMMON)
    }
  }

  async submitCreate() {
    const products = this.events.flatMap(obj =>
      obj.products
        .filter(product => product.selected === true)
        .map(product => product.id)
    )
    try {
      const user = {
        fullname: this.seller.fullname,
        username: this.seller.username,
        email: this.seller.email,
        password: this.password,
        active: this.seller.active ? Number(this.seller.active) : 0,
        business_id: this.business_id,
        business_admin: this.seller.business_admin,
      }

      const body = {
        user: user,
        location: this.location,
        products: products,
      }

      const response: any = await postSeller(body)

      if (response.data.status == 200) {
        Vue.$toast.success(`Usuario creado con éxito`)

        setTimeout(() => {
          this.$router.push({
            name: 'Compartir usuario',
            params: {
              fullname: response.data.seller.fullname,
              email: response.data.seller.email,
              password: response.data.seller.password,
              username: response.data.seller.username,
              title: 'Vendedor creado',
            },
          })
        }, 500)
      } else {
        const message = response.data.message
          ? response.data.message
          : 'Ha ocurrido un error creando el usuario'
        Vue.$toast.error(message)
      }
    } catch (e) {
      console.error(e)
      Vue.$toast.error(ErrorMessages.COMMON)
    }

    console.log(products)
  }

  mounted() {
    this.getMyData()

    if (this.$route.params.id) {
      this.title = 'Editar usuario'
      this.editMode = true
      this.userId = this.$route.params.id
      this.getSellerData(this.userId)
    }
  }

  async getMyData() {
    try {
      const { data } = await getMyData()

      this.business_id = data.business_id

      // Una vez recojido el business id, hacemos el llamado de las locations
      const _locations = await this.getLocations(this.business_id)
    } catch (e) {
      console.error(e)
    }
  }

  get isDisabled() {
    if (this.seller.fullname && this.seller.email && this.location) {
      return false
    } else {
      return true
    }
  }

  get isUpdateDisabled() {
    if (this.password === this.passwordRepeat) {
      return false
    } 
      return true
  }

  async getProductsOfLocation() {
    const { data } = await axios.get(`/locations/get-products/${this.location}`)

    this.relatedProductsOfLocation = data.length == 0 ? null : data

    const result = data.data.map(res => {
      const products = res.products.map(product => {
        return { ...product, selected: true }
      })
      return {
        ...res,
        show: products[0]?.suscribe == null ? true : false,
        selected: true,
        products: products,
      }
    })

    this.events = result
  }

  async getProductsOfLocationEdited(e) {
    const { data } = await axios.get(
      `/locations/get-products/${this.location}?userId=${this.userId}`
    )

    this.relatedProductsOfLocationEditing = data.length == 0 ? null : data

    const result = data.data.map(res => {
      const products = res.products.map(product => {
        return { ...product, selected: product.selected }
      })

      return {
        ...res,
        show: products[0]?.suscribe == null ? true : false,
        selected: products.some(product => product.selected),
        products: products,
      }
    })

    this.events = result
  }

  /* INPUT VALIDATIONS */
  get passwordRepeatErrors(): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.passwordRepeat.$dirty) return errors
    !this.$v.passwordRepeat.sameAsPassword &&
      errors.push('Las contraseñas deben ser iguales')

    return errors
  }

  get passwordErrors(): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.password.$dirty) return errors
    !this.$v.password.minLength && errors.push('La contraseña no es válida')
    return errors
  }
}
